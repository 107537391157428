body {
  background: #111;
  color: #eee;
}

a {
  color:#f98ae6;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.mainContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 64px;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 600px;
}

.header {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

.bio {
  text-align: center;
  color: #f98ae6;
  margin: 16px 0 30px;
}

.waveForm {
  display: flex;
}

.waveInput {
  height: 3em;
  flex-grow: 1;
}

.loadingImage {
  display: flex;
  justify-content: center;
}

.waveCount {
  margin: 1em auto;
  color: #eee;
}

button.waveButton:disabled {
  opacity: 0.5;
  cursor: default;
}

table, td, thead {
  border: 1px solid #f98ae6;
  border-collapse: collapse;
  padding: 4px;
}

.waveList {
  margin-top: 4em;
}

.wave {
  background: #111;
  border: 1px solid rgb(104, 4, 87);
  padding: 1em 2em;
}

.wave:hover {
  background: #21111e;
}

.waveAddress, .waveDate {
  font-size: 0.8em
}

.waveAddress {
  margin-right: 1em;
}

.waveDate {
  margin-left: 1em;
}

.waveMessage {
  padding: 1em 0;
  font-size: 1.1em;
}

.waveButton {
	border: none;
	font-family: inherit;
	font-size: inherit;
	color: inherit;
	background: none;
	cursor: pointer;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	outline: none;
	position: relative;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

.waveButton:after {
	content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	width: 100%;
	height: 0;
	top: 0;
	left: 0;
	background: #f98ae6;
}

.waveButton:hover,
.waveButton:active {
	color: #fff;
}

.waveButton:hover:after,
.waveButton:active:after {
	height: 100%;
}

.waveButton {
  cursor: pointer;
  padding: 12px;
  border: 1px solid #f98ae6;
  color: #f98ae6;
  text-transform: uppercase;
  font-size: 1.1em;
  font-weight: bold;
  background: none;
  flex-shrink: 1;
  margin-left: 1em;
}
